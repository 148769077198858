
        import * as module0 from './controllers/dropdown_controller.js';import * as module1 from './controllers/lightbox_controller.js';import * as module2 from './controllers/notes_controller.js';import * as module3 from './controllers/portraits_controller.js';import * as module4 from './controllers/video_controller.js'
        const modules = {
            "./controllers/dropdown_controller.js": module0,
            "./controllers/lightbox_controller.js": module1,
            "./controllers/notes_controller.js": module2,
            "./controllers/portraits_controller.js": module3,
            "./controllers/video_controller.js": module4,
        };
        export default modules;
      